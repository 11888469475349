import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { FinderLocation } from "@/types/FinderLocation";

import { getFinderCountryConfig } from "../utils";

interface UseVisibleSearchResultsProps {
  allSearchResults: FinderLocation[];
  productsSelected: string[];
  numExtraVisibleResults: number;
}

// handle case for no selected products
const countAvailableProducts = (productsAtLocation: string[], productsSelected: string[]) => {
  if (productsSelected.length === 0) {
    // if no products are selected, return the number of products at the location
    // if productsAtLocation is undefined, we assume there is at least 1 product
    return productsAtLocation?.length || 1;
  }
  return productsSelected.filter((product) => productsAtLocation.includes(product)).length;
};

export const useVisibleSearchResults = ({
  allSearchResults,
  productsSelected,
  numExtraVisibleResults
}: UseVisibleSearchResultsProps) => {
  const { country } = useExperience();

  const TEN_MILE_RADIUS = 16093.4;

  const { numInitialResults } = React.useMemo(() => getFinderCountryConfig(country), [country]);

  const sortedResults = React.useMemo(() => {
    return allSearchResults.slice().sort((a, b) => {
      if (productsSelected.length === 0) {
        return a.location.distance - b.location.distance;
      }
      // If multiple products are selected, we group the products by distance range, and then sort those by the number of products available. If the number of products available is the same, we sort by distance.
      if (a.location.distance <= TEN_MILE_RADIUS && b.location.distance > TEN_MILE_RADIUS) {
        return -1;
      }
      if (b.location.distance <= TEN_MILE_RADIUS && a.location.distance > TEN_MILE_RADIUS) {
        return 1;
      }

      const aAvailableProducts = countAvailableProducts(a.products, productsSelected);
      const bAvailableProducts = countAvailableProducts(b.products, productsSelected);

      if (aAvailableProducts === bAvailableProducts) {
        return a.location.distance - b.location.distance;
      }

      return bAvailableProducts - aAvailableProducts;
    });
  }, [allSearchResults, productsSelected]);

  const numResultsWithin10Miles = React.useMemo(() => {
    return sortedResults.filter((result) => result.location.distance <= TEN_MILE_RADIUS).length;
  }, [sortedResults]);

  const visibleSearchResults = React.useMemo(() => {
    if (numResultsWithin10Miles > 0 && country !== "jp") {
      const numResultsWithin10MilesToShow = Math.min(numResultsWithin10Miles, numInitialResults);
      return sortedResults.slice(0, numResultsWithin10MilesToShow + numExtraVisibleResults);
    } else {
      return sortedResults.slice(0, numInitialResults + numExtraVisibleResults);
    }
  }, [numInitialResults, numExtraVisibleResults, country, numResultsWithin10Miles, sortedResults]);

  const showSearchLocationMarker = React.useMemo(() => {
    const showingFarResults = visibleSearchResults.some(
      (result) => result.location.distance > TEN_MILE_RADIUS
    );
    if (showingFarResults) {
      return true;
    }
    return false;
  }, [visibleSearchResults]);

  return { visibleSearchResults, showSearchLocationMarker };
};
