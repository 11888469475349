import React from "react";

import { useMapsLibrary } from "./GoogleAPIProvider";

interface GoogleMapsContextProps {
  map: google.maps.Map | null;
  mapRef: React.RefObject<HTMLDivElement>;
  defaultCenter: google.maps.LatLngLiteral;
  defaultZoom: number;
  hasPanned: boolean;
  setHasPanned: React.Dispatch<React.SetStateAction<boolean>>;
  getMapCenter: () => { lat: number; lon: number };
  pixelToCoordinate: (x: number, y: number) => { lat: number; lon: number };
}

const initialContext: GoogleMapsContextProps = {
  map: null,
  defaultCenter: { lat: 0, lng: 0 },
  defaultZoom: 0,
  mapRef: { current: null },
  hasPanned: false,
  setHasPanned: () => {},
  getMapCenter: () => ({ lat: 0, lon: 0 }),
  pixelToCoordinate: () => ({ lat: 0, lon: 0 })
};

const GoogleMapsContext = React.createContext<GoogleMapsContextProps>(initialContext);

export const useMapContext = () => {
  const context = React.useContext(GoogleMapsContext);

  return context;
};

interface GoogleMapsProviderProps extends google.maps.MapOptions {
  defaultCenter: google.maps.LatLngLiteral;
  defaultZoom: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  hidden?: boolean;
}

export const GoogleMapsProvider: React.FC<GoogleMapsProviderProps> = ({
  defaultCenter,
  defaultZoom,
  children,
  ...mapOptions
}) => {
  const mapRef = React.useRef<HTMLDivElement | null>(null);
  const [mapInstance, setMapInstance] = React.useState<google.maps.Map | null>(null);
  const [hasPanned, setHasPanned] = React.useState(false);
  const googleMapsLibrary = useMapsLibrary();

  const getMapCenter = () => {
    const center = mapInstance?.getCenter();
    const lat = center?.lat();
    const lon = center?.lng();
    if (!lat || !lon) {
      return { lat: 0, lon: 0 };
    }
    return { lat, lon };
  };

  // Convert pixel coordinates to lat/lng coordinates. Returns defaultCenter if map is not loaded.
  const pixelToCoordinate = (x: number, y: number) => {
    const defaultValue = { lat: defaultCenter.lat, lon: defaultCenter.lng };
    if (!mapInstance || !mapInstance.getBounds() || !mapRef.current) {
      return defaultValue;
    }

    const latLngBounds = mapInstance.getBounds();
    const projection = mapInstance.getProjection();
    if (!projection) {
      return defaultValue;
    }

    const neBoundPx = projection.fromLatLngToPoint(latLngBounds?.getNorthEast() || defaultCenter);
    const swBoundPx = projection.fromLatLngToPoint(latLngBounds?.getSouthWest() || defaultCenter);
    if (!neBoundPx || !swBoundPx) {
      return defaultValue;
    }

    const mapWidth = mapRef.current.offsetWidth;
    const mapHeight = mapRef.current.offsetHeight;

    const percX = x / mapWidth;
    const percY = y / mapHeight;

    const newLngInPx = swBoundPx.x + (neBoundPx.x - swBoundPx.x) * percX;
    const newLatInPx = neBoundPx.y + (swBoundPx.y - neBoundPx.y) * percY; // Inverted Y-axis

    const latLng = projection.fromPointToLatLng(new google.maps.Point(newLngInPx, newLatInPx));

    return !!latLng ? { lat: latLng.lat(), lon: latLng.lng() } : defaultValue;
  };

  React.useEffect(() => {
    if (!mapInstance && mapRef.current && googleMapsLibrary) {
      const map = new googleMapsLibrary.Map(mapRef.current, {
        gestureHandling: "greedy",
        center: defaultCenter,
        zoom: defaultZoom,
        disableDefaultUI: true,
        mapId: "8887f0dd602f7e7a",
        ...mapOptions
      });

      setMapInstance(map);
    }
  }, [defaultCenter, defaultZoom, googleMapsLibrary, mapInstance, mapOptions]);

  React.useEffect(() => {
    if (!mapInstance) {
      return;
    }

    const handleDragEnd = () => {
      setHasPanned(true);
    };

    mapInstance.addListener("dragend", handleDragEnd);

    return () => {
      google.maps.event.clearListeners(mapInstance, "dragend");
    };
  }, [mapInstance]);

  return (
    <GoogleMapsContext.Provider
      value={{
        map: mapInstance,
        defaultCenter,
        defaultZoom,
        mapRef,
        hasPanned,
        setHasPanned,
        getMapCenter,
        pixelToCoordinate
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  );
};
