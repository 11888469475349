import { Box, Button } from "@modernatx/ui-kit-react";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { FinderBlockProps } from "@/types/Block";

import { ScrollToListItem } from "./FinderList";
import { useFinder } from "./hooks/useFinder";

const getLoadMoreButtonText = (
  numResultsToShow: number,
  template?: { singular: string; plural: string }
) => {
  if (!template) {
    return;
  } else {
    return numResultsToShow === 1
      ? template.singular.replace("{num}", numResultsToShow.toString())
      : template.plural.replace("{num}", numResultsToShow.toString());
  }
};

interface LoadMoreButtonProps {
  loadMoreTemplate: FinderBlockProps["loadMoreTemplate"];
  inMobileMapView: boolean;
  scrollToListItem: ScrollToListItem;
}
export const LoadMoreButton = ({
  loadMoreTemplate,
  inMobileMapView,
  scrollToListItem
}: LoadMoreButtonProps) => {
  const { country } = useExperience();

  const buttonContainerRef = React.useRef<HTMLButtonElement | null>(null);
  const previousButtonOffset = React.useRef<number>(0);

  const {
    allSearchResults,
    setNumExtraVisibleResults,
    visibleSearchResults,
    visibleSearchResults: locations
  } = useFinder();

  React.useEffect(() => {
    scrollToListItem(previousButtonOffset.current);
  }, [locations, scrollToListItem]);

  const numResultsToReveal = React.useMemo(() => {
    const numResultsNotShowing = allSearchResults.length - visibleSearchResults.length;
    return Math.min(numResultsNotShowing, 10);
  }, [allSearchResults, visibleSearchResults]);

  const buttonText = React.useMemo(
    () => getLoadMoreButtonText(numResultsToReveal, loadMoreTemplate),
    [numResultsToReveal, loadMoreTemplate]
  );

  const hasResults = React.useMemo(() => numResultsToReveal > 0, [numResultsToReveal]);

  const onClick = React.useCallback(() => {
    // track the current offsetTop of the buttonContainerRef between renders.
    // This is used in the above useEffect to scroll to the previous location of the button when more items are revealed.
    previousButtonOffset.current = buttonContainerRef.current?.offsetTop || 0;
    setNumExtraVisibleResults(
      (currNumAdditionalResults) => currNumAdditionalResults + numResultsToReveal
    );
  }, [setNumExtraVisibleResults, numResultsToReveal]);

  const showButton = React.useMemo(
    () => hasResults && buttonText && !inMobileMapView && country !== "jp",
    [hasResults, buttonText, inMobileMapView, country]
  );

  return !showButton ? null : (
    <Box ref={buttonContainerRef} sx={{ display: "flex", justifyContent: "center", padding: 4 }}>
      <Button variant="secondary" onClick={onClick}>
        {/* button text will never be empty here - if it is, or undefined, the button will not render due to hte showButton memoized value above. This line is necessary because TS can't infer that buttonText is always string due to how we're conditionally checking it. */}
        {buttonText || ""}
      </Button>
    </Box>
  );
};
