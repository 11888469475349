import { DropdownOption } from "@modernatx/ui-kit-react";
import { useRouter } from "next/router";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";
import { FinderLocation } from "@/types/FinderLocation";

import { useMapContext } from "../googleMaps/GoogleMapsProvider";
import { getFinderCountryConfig } from "../utils";
import { useVisibleSearchResults } from "./useVisibleSearchResults";

type SearchType = "unbranded" | "branded";

interface Place {
  address: string;
  id: string;
  latitude: number;
  longitude: number;
}

interface FinderContextValue {
  allSearchResults: FinderLocation[];
  locationSelected: FinderLocation | null;
  locationSelectedSet: (location: FinderLocation) => void;
  mapBasedSearch: (searchMarker: HTMLDivElement | null, isMobileView: boolean) => void;
  place: Place | null;
  productsSelect: (products: string[]) => void;
  productsSelected: string[];
  searching: boolean;
  searchOptionSelect: (option: DropdownOption) => void;
  searchOptionSelected: DropdownOption | null;
  visibleSearchResults: FinderLocation[];
  setNumExtraVisibleResults: React.Dispatch<React.SetStateAction<number>>;
  showSearchLocationMarker: boolean;
  searchType: SearchType;
  searchValue: string;
  searchValueSet: (value: string) => void;
}

const FinderContext = React.createContext<FinderContextValue>({
  allSearchResults: [],
  locationSelected: null,
  locationSelectedSet: () => {},
  mapBasedSearch: () => {},
  place: null,
  productsSelect: () => {},
  productsSelected: [],
  searching: false,
  searchOptionSelect: () => {},
  searchOptionSelected: null,
  setNumExtraVisibleResults: () => {},
  visibleSearchResults: [],
  showSearchLocationMarker: false,
  searchType: "branded",
  searchValue: "",
  searchValueSet: () => {}
});

const getProductsSelected = (query: ReturnType<typeof useRouter>["query"], products: string[]) => {
  const productsQuery = Array.isArray(query.products) ? query.products[0] : query.products;
  return productsQuery?.split(",").filter((p) => products.includes(p)) || [];
};

export const FinderContextProvider: React.FC<
  React.PropsWithChildren<{
    products: string[];
    searchType: SearchType;
  }>
> = ({ children, searchType, products }) => {
  const { country, language, alternates } = useExperience();
  const { pathname, query, push, replace } = useRouter();
  const { getMapCenter, pixelToCoordinate } = useMapContext();
  const currentPathname = React.useMemo(() => {
    const alternateMatch = alternates?.find(
      (alternate) => alternate.country === country && alternate.language === language
    );
    if (!alternateMatch) {
      return pathname;
    } else {
      const { url } = alternateMatch;
      const newPathname = new URL(url).pathname;
      return newPathname;
    }
  }, [alternates, country, language, pathname]);
  const [locationSelected, locationSelectedSet] = React.useState<FinderLocation | null>(null);
  const [place, placeSet] = React.useState<null | Place>(null);
  const [productsSelected, productsSelectedSet] = React.useState<string[]>(
    getProductsSelected(query, products)
  );
  const [searching, searchingSet] = React.useState(false);
  const [searchOptionSelected, searchOptionSelect] = React.useState<DropdownOption | null>(null);
  const [allSearchResults, setAllSearchResults] = React.useState<FinderLocation[]>([]);
  const [searchValue, searchValueSet] = React.useState("");
  const [numExtraVisibleResults, setNumExtraVisibleResults] = React.useState(0);
  const placeId = React.useRef<null | string>(null);
  const placeIdQuery = Array.isArray(query.placeId) ? query.placeId[0] : query.placeId;
  const productsQueryInitial = productsSelected.join(",");

  const setNewPlace = React.useCallback((newPlace: Place | null) => {
    placeSet(newPlace);
    locationSelectedSet(null);
    setAllSearchResults([]);
  }, []);

  const { size } = React.useMemo(() => getFinderCountryConfig(country), [country]);

  const { visibleSearchResults, showSearchLocationMarker } = useVisibleSearchResults({
    allSearchResults,
    productsSelected,
    numExtraVisibleResults
  });

  const setNewSearchResults = React.useCallback((newSearchResults: FinderLocation[]) => {
    setNumExtraVisibleResults(0);
    setAllSearchResults(newSearchResults);
    locationSelectedSet(null);
  }, []);

  React.useEffect(() => {
    if (placeId.current !== placeIdQuery && country && language) {
      if (placeIdQuery) {
        placeId.current = placeIdQuery;

        if (placeIdQuery.startsWith("map_")) {
          const [, lat, lon] = placeIdQuery.split("_");
          if (!lat || !lon) {
            setNewPlace(null);
            return;
          }
          setNewPlace({
            address: searchOptionSelected?.label || "",
            id: placeIdQuery,
            latitude: parseFloat(lat),
            longitude: parseFloat(lon)
          });
          return;
        }

        const searchParams = new URLSearchParams({
          country,
          language,
          placeId: placeIdQuery
        });
        fetch(`/api/location-details?${searchParams.toString()}`)
          .then((response) => {
            if (response.status >= 400) {
              throw new Error("Failed to fetch place.");
            }
            return response;
          })
          .then((response) => response.json())
          .then((data: Place) => {
            setNewPlace(data);
            searchValueSet(data.address);
          })
          .catch(() => {
            setNewPlace(null);
          });
      } else {
        setNewPlace(null);
        searchValueSet("");
      }
    }
  }, [country, language, placeIdQuery, setNewPlace, searchValueSet, searchOptionSelected?.label]);

  const fetchPartnerLocations = React.useCallback(
    async (params: URLSearchParams) => {
      const tokenResponse = await fetch("/api/auth/token");
      const { token, partnerApiGatewayUrl } = await tokenResponse.json();

      try {
        const response = await fetch(`${partnerApiGatewayUrl}?${params.toString()}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        });

        const data = await response.json();
        const locations: FinderLocation[] = data.map((location: FinderLocation) => {
          return {
            ...location,
            id: location.customer.id
          };
        });
        return locations;
      } catch (error) {
        setNewSearchResults([]);
      }
      return [];
    },
    [setNewSearchResults]
  );

  const fetchLocations = React.useCallback(
    async (params: URLSearchParams) => {
      try {
        const response = await fetch(`/api/product-locations?${params.toString()}`);
        if (response.status >= 400) {
          throw new Error("Failed to fetch locations.");
        }
        const { locations }: { locations: FinderLocation[] } = await response.json();
        if (params.get("type") === "unbranded" && country === "us") {
          const filteredLocations = locations.filter(
            (location: FinderLocation) =>
              !location.customer.name.toLowerCase().includes("walgreens")
          );
          return filteredLocations;
        }
        return locations;
      } catch (error) {
        setNewSearchResults([]);
      }
      return [];
    },
    [setNewSearchResults, country]
  );

  React.useEffect(() => {
    if (place) {
      const productsQuery = searchType === "unbranded" ? "unbranded" : productsQueryInitial;
      searchingSet(true);

      const searchParams = new URLSearchParams({
        country: country as string,
        lat: String(place.latitude),
        lon: String(place.longitude),
        products: productsQuery,
        type: searchType
      });

      const partnerLocationParams = new URLSearchParams({
        lat: String(place.latitude),
        lon: String(place.longitude)
      });

      const getLocations = async () => {
        let resolvedLocations: FinderLocation[] = [];

        try {
          if (searchType === "unbranded" && country === "us") {
            const partnerLocationsPromise = fetchPartnerLocations(partnerLocationParams).catch(
              () => {
                return [];
              }
            );

            const unbrandedLocationsPromise = fetchLocations(searchParams).catch(() => {
              return [];
            });

            // Fetch both locations concurrently
            const [partnerResults, unbrandedResults] = await Promise.all([
              partnerLocationsPromise,
              unbrandedLocationsPromise
            ]);

            resolvedLocations = [...partnerResults, ...unbrandedResults];
          } else {
            resolvedLocations = await fetchLocations(searchParams).catch(() => {
              return [];
            });
          }

          // Sort locations by distance and return the first "size" locations - where size is the total number of locations we can display.
          // This is necessary because in the unbranded finder, we fetch from our datastore up to 30 results, and then also we fetch from walgreens API
          // sometimes when we combine the two, we get more results than we can show.
          const sortedAndSlicedResolvedLocations = resolvedLocations
            .sort((a, b) => a.location.distance - b.location.distance)
            .slice(0, size);
          setNewSearchResults(sortedAndSlicedResolvedLocations);
        } catch (error) {
          setNewSearchResults([]);
        } finally {
          searchingSet(false); // Ensure the searching state is reset
        }
      };

      getLocations();
    }
  }, [
    country,
    fetchLocations,
    fetchPartnerLocations,
    place,
    productsQueryInitial,
    searchType,
    setNewSearchResults,
    size
  ]);

  const handleSearchOptionSelect = React.useCallback(
    (option: DropdownOption) => {
      searchOptionSelect(option);
      searchValueSet(option.label);

      push(
        {
          pathname: currentPathname,
          query: {
            ...query,
            placeId: option.value
          }
        },
        undefined,
        { scroll: false }
      );
    },
    [currentPathname, push, query]
  );

  // mapBasedSearch is called when the "Search this area" button is clicked
  // if there are no results, we can just find the map center using Google Maps API (getMapCenter)
  // if there are results, the search marker will be positioned off-center, so we
  // calculate the new search position based on the marker's position over the map
  const mapBasedSearch = async (searchMarker: HTMLDivElement | null, isMobileView: boolean) => {
    if (!country || !language) {
      return;
    }

    try {
      const mapContainer = document.querySelector(".gm-style");
      const getSearchCoordinates = () => {
        if (!searchMarker || !mapContainer) {
          return getMapCenter(); // Fallback to map center
        }

        const markerRect = searchMarker.getBoundingClientRect();
        const mapRect = mapContainer.getBoundingClientRect();

        const offsetX = markerRect.left - mapRect.left + markerRect.width / 2;
        const offsetY = markerRect.top - mapRect.top + markerRect.height / 2;

        return (allSearchResults.length || place) && !isMobileView
          ? pixelToCoordinate(offsetX, offsetY)
          : getMapCenter();
      };

      const { lat, lon } = getSearchCoordinates();

      const reverseGeocodeResponse = await fetch(
        `/api/reverse-geocode?lat=${lat}&lon=${lon}&country=${country}&language=${language}`,
        { cache: "no-store" }
      );

      if (!reverseGeocodeResponse.ok) {
        setNewPlace(null);
        return;
      }

      const geoPlace = await reverseGeocodeResponse.json();

      if (!geoPlace || !geoPlace.address) {
        setNewPlace(null);
        return;
      }

      // Hand off the place id to search option select, as if the user selected it from the search results dropdown
      handleSearchOptionSelect({
        label: geoPlace.address,
        value: `map_${lat}_${lon}`
      });
    } catch (error) {
      setNewPlace(null);
    }
  };

  const handleProductsSelect = React.useCallback(
    (productsNext: string[]) => {
      const productsFiltered = getProductsSelected({ products: productsNext.join(",") }, products);
      productsSelectedSet(productsFiltered);
      // Remove the products from the query in case all configuration has been removed.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { products: productsPrev, ...queryOther } = query;
      replace(
        {
          query: {
            ...queryOther,
            ...(!!productsFiltered.length ? { products: productsFiltered.join(",") } : {})
          }
        },
        undefined,
        { scroll: false }
      );
    },
    [products, replace, query]
  );

  return (
    <FinderContext.Provider
      value={{
        allSearchResults,
        locationSelected,
        locationSelectedSet,
        mapBasedSearch,
        place,
        productsSelect: handleProductsSelect,
        productsSelected,
        searching,
        searchOptionSelect: handleSearchOptionSelect,
        searchOptionSelected,
        setNumExtraVisibleResults,
        visibleSearchResults,
        showSearchLocationMarker,
        searchType,
        searchValue,
        searchValueSet
      }}
    >
      {children}
    </FinderContext.Provider>
  );
};

export const useFinder = () => React.useContext(FinderContext);
