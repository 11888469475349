import Image from "next/image";
import React from "react";

import { FinderLocation } from "@/types/FinderLocation";

import { AdvancedMarker } from "./googleMaps/AdvancedMarker";
import { useMapContext } from "./googleMaps/GoogleMapsProvider";
import { Map } from "./googleMaps/Map";
import { useFinder } from "./hooks/useFinder";
import MapViewController from "./MapViewController";

const FinderMarker = ({ active }: { active: boolean }) => {
  return (
    <Image
      alt="Custom Marker"
      src={`/assets/images/finder-icon${active ? "-active" : ""}.svg`}
      width={50}
      height={50}
    />
  );
};
FinderMarker.displayName = "FinderMarker";

export const SearchLocationMarker = React.forwardRef<HTMLImageElement>((_, ref) => {
  return (
    <Image
      ref={ref}
      alt="Custom Marker"
      src={`/assets/images/svgs/crosshairs.svg`}
      width={34}
      height={34}
      style={{ transform: "translateY(50%)" }}
    />
  );
});

SearchLocationMarker.displayName = "SearchLocationMarker";

interface FinderMapProps {
  hidden: boolean;
  results: FinderLocation[];
}

const FinderMap: React.FC<FinderMapProps> = ({ hidden }) => {
  const {
    locationSelected,
    locationSelectedSet,
    place,
    showSearchLocationMarker,
    visibleSearchResults: results
  } = useFinder();
  const { hasPanned } = useMapContext();
  const isSelectedLocation = (location?: FinderLocation) => {
    return locationSelected?.customer.id === location?.customer.id;
  };
  const handleMarkerClick = (searchResult: FinderLocation) => () =>
    locationSelectedSet(searchResult);

  return (
    <Map hidden={hidden}>
      <MapViewController hidden={hidden} />
      {results?.map((searchResult) => (
        <AdvancedMarker
          key={searchResult.customer.id}
          position={{
            lat: searchResult.location.position.lat,
            lng: searchResult.location.position.lon
          }}
          zIndex={isSelectedLocation(searchResult) ? 1000 : null}
          onClick={handleMarkerClick(searchResult)}
        >
          <FinderMarker active={isSelectedLocation(searchResult)} />
        </AdvancedMarker>
      ))}
      {place && showSearchLocationMarker && !hasPanned && (
        <AdvancedMarker
          position={{
            lat: place.latitude,
            lng: place.longitude
          }}
          zIndex={null}
        >
          <SearchLocationMarker />
        </AdvancedMarker>
      )}
    </Map>
  );
};

export default FinderMap;
