import { DropdownOption } from "@modernatx/ui-kit-react";
import { debounce } from "lodash";
import React from "react";

import { useExperience } from "@/context/ExperienceContext";

import { useMapContext } from "../googleMaps/GoogleMapsProvider";

// The Japan Team has requested that we add some additional options to the search dropdown.
// These options come from AWS Places API and are hardcoded here to help resolve searches
// for Kawasaki City, Japan. This is a temporary solution until we can implement a more
// robust solution for the Japan Finder (or not).
const JP_ADDITIONAL_OPTIONS = [
  {
    description: "神奈川県川崎市宮前区",
    place_id:
      "AQACAFQApctM45Cbz5JpBHmb9I1PuGOp36lI6M5-CEFturDCTbckulbf_7jt4TL6C8DFGsc8cZDBaqbDjC9fQjm-r9yyB-5sYqFETEw4MmdDr7SKo9k9jGj6txW39FZ9rxk4ptngiixs2SKo5vKAqdMaoshgA7GCXow"
  },
  {
    description: "神奈川県川崎市高津区",
    place_id:
      "AQACAFQAa5VoFJKl-xw9CKnv09_gl239eVzhSiPCHC33dsBzKyahMAJN6wOzeQbFc12R2nfM4Hx43BmsCAsFbMRpDj8bKXX4QBW-OVpg6IdOHAKog7Q22whOTd2nS3ofhP8YOTqiuSeFRp3NHbVb7oAYahOReS28Jns"
  },
  {
    description: "神奈川県川崎市中原区",
    place_id:
      "AQACAFQA61wJ-pOj3HszQslZuKAw6pzt_U77QFy0zqb9n5EEbqAGhS2TCguLm2RANxujrxBe84EMB2llm8o5mbzkxtE1Tq4wqUHJn2bCWwruqo3jjFL4xWX7xpk44I1o9O2q0G5Wj1GdjFPQK0dj7PT2xadQdof9AQ0"
  }
];

export const useLocationSearch = ({
  searchValue,
  searchValueSet,
  searchOptionSelect
}: {
  searchValue: string;
  searchValueSet: (value: string) => void;
  searchOptionSelect: (option: DropdownOption) => void;
}) => {
  const [showNoResults, setShowNoResults] = React.useState<boolean>(false);
  const [pendingSubmit, setPendingSubmit] = React.useState<boolean>(false);

  const { isFetchingSuggestions, searchSuggestions } = useAutocompleteSearch(searchValue);
  const { setHasPanned } = useMapContext();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    searchValueSet(event.target.value);
  };

  const searchSuggestionsList = searchSuggestions.map((suggestion) => ({
    label: suggestion.description,
    value: suggestion.place_id
  }));

  const handleSearchSuggestionClick = React.useCallback(
    (option: DropdownOption) => {
      // Reset the map panning state at the new location.
      setHasPanned(false);
      /* 
      Need this set timout to handle when our keyboard finishes composition event for special characters (like japanese)
      It waits to set search options till after composition event is finished from user. 
      This avoids a weird bug where we update state of the input to be our option we've selected, and THEN the browser composition end event fires, adding special characters to the front of the string.
      Read about composiiton events here: https://developer.mozilla.org/en-US/docs/Web/API/Element/composition_event

      known bug still exists for japan that causes input to be focused after pressing enter. Will continue to investigate
      */

      setTimeout(() => searchOptionSelect(option), 1);
    },
    [searchOptionSelect, setHasPanned]
  );

  const handleSearchSubmit = React.useCallback(() => {
    if (isFetchingSuggestions) {
      // If autocomplete search is pending, wait to submit until that fetch is complete.
      setPendingSubmit(true);
    } else if (searchSuggestionsList.length > 0) {
      const defaultOption = searchSuggestionsList[0];
      handleSearchSuggestionClick(defaultOption!);
    }
  }, [isFetchingSuggestions, searchSuggestionsList, handleSearchSuggestionClick]);

  // Handle pending submit once suggestions are fetched. This supports the case
  // where a user submits their search before the suggestions are finished fetching.
  React.useEffect(() => {
    if (pendingSubmit && !isFetchingSuggestions) {
      handleSearchSubmit();
      setPendingSubmit(false);
    }
  }, [pendingSubmit, isFetchingSuggestions, handleSearchSubmit]);

  // Handle "No Results" when search suggestions are empty.
  React.useEffect(() => {
    setShowNoResults(
      !isFetchingSuggestions && searchSuggestionsList.length === 0 && searchValue.length > 1
    );
  }, [searchSuggestionsList, searchValue, isFetchingSuggestions]);

  return {
    handleSearchChange,
    handleSearchSubmit,
    handleSearchSuggestionClick,
    searchSuggestionsList,
    showNoResults
  };
};

interface SearchSuggestion {
  description: string;
  place_id: string;
}

const fetchAutocompleteSearch = debounce(
  async (searchParams: URLSearchParams, callback: (data: SearchSuggestion[]) => void) => {
    const country = searchParams.get("country");
    const additionalOptions = additionalOptionMatches(searchParams.get("input") || "", country);

    return fetch(`/api/location-search-autocomplete?${searchParams.toString()}`)
      .then((response) => response.json())
      .then((data: { predictions: SearchSuggestion[] }) => {
        callback([...additionalOptions, ...data.predictions]);
      });
  },
  200
);

function useAutocompleteSearch(input: string = "") {
  const { country, language } = useExperience();
  const [searchSuggestions, setSearchSuggestions] = React.useState<{
    [input: string]: SearchSuggestion[];
  }>({});

  const fetchCallback = React.useCallback(
    (data: SearchSuggestion[]) => {
      setSearchSuggestions((prev) => ({
        ...prev,
        [input]: data
      }));
    },
    [input]
  );

  React.useEffect(() => {
    if (input && input.length > 1 && country && language) {
      fetchAutocompleteSearch(new URLSearchParams({ country, input, language }), fetchCallback);
    } else {
      fetchCallback([]);
    }
  }, [country, input, language, fetchCallback]);

  return {
    isFetchingSuggestions: !searchSuggestions[input],
    searchSuggestions: searchSuggestions[input] || []
  };
}

function additionalOptionMatches(input: string, country: string | null): SearchSuggestion[] {
  if (!input || input.length < 2 || !country || country !== "jp") {
    return [];
  }

  return JP_ADDITIONAL_OPTIONS.filter((option) => option.description.includes(input));
}
