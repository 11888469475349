import { Box, Button, Grid, GridColumn } from "@modernatx/ui-kit-react";
import React from "react";
import { css, Global } from "theme-ui";

import { SearchLocationMarker } from "./FinderMap";
import { useMapContext } from "./googleMaps/GoogleMapsProvider";
import { useFinder } from "./hooks/useFinder";

export const SearchThisAreaButton = ({ onClick, text }: { onClick: () => void; text: string }) => {
  const { hasPanned, setHasPanned } = useMapContext();

  const handleClick = () => {
    onClick();
    setHasPanned(false);
  };

  return (
    <Box
      id="search-this-area"
      sx={{
        bg: "background01",
        borderRadius: "large",
        width: "fit-content",
        height: "fit-content",
        boxShadow: "boxShadow02",
        transition: "easeOutMedium",
        opacity: hasPanned ? 1 : 0,
        visibility: hasPanned ? "visible" : "hidden",
        pointerEvents: "auto"
      }}
    >
      <Button icon="magnifying-glass" variant="secondary" aria-label={text} onClick={handleClick}>
        {text}
      </Button>
    </Box>
  );
};

// FinderList renders the list of locations, taking into consideration the
// selected view and size breakpoints
export const FinderListViewWrapper = ({
  children,
  isMobileView,
  selectedView,
  searchThisAreaText
}: {
  children: React.ReactNode;
  isMobileView: boolean;
  selectedView: "list" | "map";
  searchThisAreaText: string;
}) => {
  const { mapBasedSearch, visibleSearchResults: results, place } = useFinder();
  const { hasPanned } = useMapContext();
  const searchLocationMarkerRef = React.useRef<HTMLDivElement | null>(null);

  const handleSearch = React.useCallback(() => {
    mapBasedSearch(searchLocationMarkerRef.current, isMobileView);
  }, [isMobileView, mapBasedSearch]);

  // positions the "Use Ctrl + scroll to zoom the map" message so that it is always visible.
  // If there are results, the message is positioned to the right of the results list
  const GoogleMapsControlMessageStyles = results.length
    ? css({
        ".gm-style-mot": {
          width: "50%",
          float: "right",
          textAlign: "center",
          pl: 10,
          pr: 2
        }
      })
    : {};

  if (!isMobileView) {
    return (
      <Grid
        sx={{
          height: "100%",
          width: "100%",
          pointerEvents: "none",
          position: "absolute",
          py: 6
        }}
      >
        <Global styles={GoogleMapsControlMessageStyles} />
        <GridColumn
          size={!!results.length || place ? [4, 8, 6] : [2, 4, 3]}
          sx={{ justifyContent: "start", height: "100%" }}
        >
          <Box
            sx={{
              maxHeight: "100%",
              width: "100%",
              boxShadow: "boxShadow02",
              borderRadius: "large"
            }}
          >
            {children}
          </Box>
        </GridColumn>
        <GridColumn id="view-column" size={[4, 8, 6]} sx={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              pointerEvents: "none",
              width: "100%",
              justifyContent: "center"
            }}
          >
            <SearchThisAreaButton text={searchThisAreaText} onClick={handleSearch} />
            <Box />
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transition: "easeOutMedium",
              opacity: hasPanned ? 1 : 0,
              visibility: hasPanned ? "visible" : "hidden"
            }}
          >
            <div ref={searchLocationMarkerRef}>
              <SearchLocationMarker />
            </div>
          </Box>
        </GridColumn>
      </Grid>
    );
  }
  return (
    <>
      {selectedView === "map" && (
        <Box
          id="view-column"
          sx={{
            position: "absolute",
            width: "100%",
            justifyContent: "center",
            display: "flex",
            pointerEvents: "none",
            mt: 4
          }}
        >
          <SearchThisAreaButton text={searchThisAreaText} onClick={handleSearch} />
        </Box>
      )}
      {children}
    </>
  );
};
