import React from "react";

import { useMapsLibrary } from "./googleMaps/GoogleAPIProvider";
import { useMapContext } from "./googleMaps/GoogleMapsProvider";
import { useFinder } from "./hooks/useFinder";
import { useIsMobile } from "./hooks/useIsMobile";

// Handles the logic for the map view, including setting the bounds and zoom level, and panning to a selected location
// This component is NOT a wrapper around the Google Maps API, but rather a custom controller built specifically for the Finder
const MapViewController = ({ hidden }: { hidden: boolean }) => {
  const { map, defaultZoom } = useMapContext();
  const singleLocationZoomLevel = 14;
  const [lastZoomLevel, setLastZoomLevel] = React.useState<number>(defaultZoom);
  if (!map) {
    throw new Error("MapViewController must be used within a Map component");
  }
  const mapLibrary = useMapsLibrary();
  const isMobileView = useIsMobile();
  const { place, showSearchLocationMarker, locationSelected, visibleSearchResults } = useFinder();

  const applyOffset = React.useCallback(() => {
    if (!isMobileView && map) {
      const viewportColumn = document.querySelector("#view-column");
      if (!viewportColumn) {
        return;
      }
      const panOffsetX = viewportColumn.clientWidth / 2;
      map.panBy(-panOffsetX, 0);
    }
  }, [isMobileView, map]);

  const mapBounds = React.useMemo(() => {
    const bounds = new google.maps.LatLngBounds();

    if (!!visibleSearchResults?.length) {
      visibleSearchResults.forEach((result) => {
        bounds.extend({
          lat: result.location.position.lat,
          lng: result.location.position.lon
        });
      });

      if (place && showSearchLocationMarker) {
        bounds.extend({
          lat: place.latitude,
          lng: place.longitude
        });
      }

      // TLDR of the next several lines: make bounds square.
      // Square bounds ensures that the map is zoomed out enough to show all search results.
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();

      const latCenter = (ne.lat() + sw.lat()) / 2;
      const lngCenter = (ne.lng() + sw.lng()) / 2;

      // find the longest edge of the rectangle
      let latDiff = ne.lat() - sw.lat();
      let lngDiff = (ne.lng() - sw.lng()) * Math.cos(latCenter * (Math.PI / 180)); // Correct for longitude distortion
      const maxDiff = Math.max(latDiff, lngDiff);

      latDiff = maxDiff;
      lngDiff = maxDiff / Math.cos(latCenter * (Math.PI / 180));

      const squareSw = new google.maps.LatLng(latCenter - latDiff / 2, lngCenter - lngDiff / 2);
      const squareNe = new google.maps.LatLng(latCenter + latDiff / 2, lngCenter + lngDiff / 2);

      return new google.maps.LatLngBounds(squareSw, squareNe);
    }

    return bounds;
  }, [place, visibleSearchResults, showSearchLocationMarker]);

  // Set the bounds of the map to fit all search results --> Don't need to do this on render's where the map is hidden.
  React.useEffect(() => {
    if (!mapLibrary || hidden || !map || mapBounds.isEmpty()) {
      return;
    }
    if (!!visibleSearchResults?.length) {
      map.fitBounds(mapBounds, { top: 50, left: 8, right: 8 });
      if (visibleSearchResults.length === 1 && !showSearchLocationMarker) {
        map.setZoom(singleLocationZoomLevel);
      }
      setLastZoomLevel((prevZoom) => map.getZoom() || prevZoom);
      applyOffset();
    } else if (place) {
      map.setCenter({
        lat: place.latitude,
        lng: place.longitude
      });
      map.setZoom(singleLocationZoomLevel - 1);
      setLastZoomLevel(singleLocationZoomLevel - 1);
    }
  }, [
    applyOffset,
    hidden,
    mapBounds,
    mapLibrary,
    visibleSearchResults,
    map,
    place,
    showSearchLocationMarker
  ]);

  // Define behavior for when a location is selected
  React.useEffect(() => {
    if (!!map && !!locationSelected) {
      map.panTo({
        lat: locationSelected.location.position.lat,
        lng: locationSelected.location.position.lon
      });
      map.setZoom(lastZoomLevel);
      applyOffset();
    }
  }, [applyOffset, lastZoomLevel, locationSelected, map, place]);
  return null;
};

export default React.memo(MapViewController);
