import { Box } from "@modernatx/ui-kit-react";
import React from "react";

import { useMapContext } from "./GoogleMapsProvider";

interface MapProps extends google.maps.MapOptions {
  children?: React.ReactNode;
  hidden?: boolean;
}

export const Map: React.FC<MapProps> = ({ children, hidden }: MapProps) => {
  const { mapRef, map } = useMapContext();

  return (
    <Box
      ref={mapRef}
      sx={{
        width: "100%",
        height: "100%",
        // @ts-ignore
        minHeight: hidden ? 0 : ["300px", null, "600px"],
        visibility: hidden ? "hidden" : "visible"
        // position: "relative"
      }}
    >
      {!!map && children}
    </Box>
  );
};
