import React from "react";

import { XShareProps } from "@/types/Block";

const getShareToFacebookFunction = (url: string | undefined) => {
  if (!url) {
    return undefined;
  } else {
    return () =>
      window.open(`https://facebook.com/sharer/sharer.php?u=${encodeURI(url)}`, "_blank");
  }
};

const getShareToXFunction = ({ description, url, hashtags }: XShareProps) => {
  if (!url) {
    return undefined;
  } else {
    return () =>
      window.open(
        `https://x.com/share?url=${encodeURI(url)}` +
          (description ? `&text=${encodeURIComponent(description)}` : "") +
          (hashtags ? `&hashtags=${encodeURIComponent(hashtags.join(","))}` : ""),
        "_blank"
      );
  }
};

const getShareToLinkedinFunction = (url: string | undefined) => {
  if (!url) {
    return undefined;
  } else {
    return () =>
      window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`, "_blank");
  }
};

export const useSocialShareFunctions = ({
  facebookUrl,
  linkedInUrl,
  xShareProps
}: {
  facebookUrl?: string;
  linkedInUrl?: string;
  xShareProps?: XShareProps;
}) => {
  return React.useMemo(() => {
    return {
      shareToFacebook: getShareToFacebookFunction(facebookUrl),
      shareToX: getShareToXFunction(xShareProps || {}),
      shareToLinkedin: getShareToLinkedinFunction(linkedInUrl)
    };
  }, [facebookUrl, linkedInUrl, xShareProps]);
};
